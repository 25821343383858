import '../styles/globals.css';
import { useEffect } from 'react';

import Script from 'next/script';
import type { AppProps } from 'next/app';

/**
 * Providers
 */
import {
  ChakraProvider,
  Flex,
  List,
  ListIcon,
  ListItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { SessionProvider } from 'next-auth/react';
import { SidebarDrawerProvider } from '../context/sidebar';

/**
 * Trackers
 */
import { hotjar } from 'react-hotjar';
import * as fbPixel from '../utils/facebook/facebookPixel';

/**
 * Components
 */
import NextNProgress from 'nextjs-progressbar';

/**
 * Theme
 */
import { theme } from '../styles/theme';
import { useRouter } from 'next/router';
import { AdditionalUserInfoProvider } from '../context/additionalUserInfo';
import { FiLogIn, FiLogOut } from 'react-icons/fi';

function MyApp({ Component, pageProps }: AppProps) {
  const env = process.env.NODE_ENV;

  const router = useRouter();

  useEffect(() => {
    if (env === 'production') hotjar.initialize(2915241, 6);
  }, [env]);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbPixel.pageview();

    const handleRouteChange = () => {
      fbPixel.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.on('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ChakraProvider theme={theme}>
      <SessionProvider>
        <AdditionalUserInfoProvider>
          <SidebarDrawerProvider>
            {/* FB Pixel */}
            {process.env.NODE_ENV === 'production' ? (
              <>
                <Script
                  id="keps"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
                `,
                  }}
                />
                <Script
                  id="clarity"
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html: `
                  (function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
                  `,
                  }}
                />
                <Script id="vector-script" strategy="afterInteractive">
                  {`
                    !function(e,r){try{if(e.vector)return void console.log("Vector snippet included more than once.");var t={};t.q=t.q||[];for(var o=["load","identify","on"],n=function(e){return function(){var r=Array.prototype.slice.call(arguments);t.q.push([e,r])}},c=0;c<o.length;c++){var a=o[c];t[a]=n(a)}if(e.vector=t,!t.loaded){var i=r.createElement("script");i.type="text/javascript",i.async=!0,i.src="https://cdn.vector.co/pixel.js";var l=r.getElementsByTagName("script")[0];l.parentNode.insertBefore(i,l),t.loaded=!0}}catch(e){console.error("Error loading Vector:",e)}}(window,document);
                    vector.load("${process.env.NEXT_PUBLIC_VECTOR_TAG_ID}");
                  `}
                </Script>
                <Script
                  strategy="afterInteractive"
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
                />
                <Script id="google-analytics" strategy="afterInteractive">
                  {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
                  `}
                </Script>
              </>
            ) : (
              <></>
            )}

            <Script id="init-fb-sdk">
              {`
          window.fbAsyncInit = function() {
            FB.init({
              appId            : '${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}',
              autoLogAppEvents : true,
              xfbml            : true,
              version          : 'v13.0'
            });
          };
        `}
            </Script>
            <Script
              id="load-fb-sdk"
              defer
              async
              crossOrigin="anonymous"
              src="https://connect.facebook.net/en_US/sdk.js"
              strategy="lazyOnload"
            />
            <NextNProgress
              color="#7903EF"
              startPosition={0.3}
              stopDelayMs={200}
              height={5}
            />
            <Component {...pageProps} />
            <MaintanceToast />
            {/* <Tooltip label="Dúvidas ou sugestões? Entre em contato"> */}
            {/* <Button
              height="56px"
              width="56px"
              as="a"
              borderRadius="50%"
              bg={'#25D366'}
              position="fixed"
              bottom={{ base: '20px', md: '40px' }}
              right={{ base: 5, md: '40px' }}
              zIndex='1'
              href="https://wa.me/5547992160811"
              target='_blank'
            >
              <Box
                style={{
                  top: -4,
                  right: 0,
                  position: 'absolute',
                  height: 16,
                  width: 16,
                  borderRadius: 12,
                  background: '#fc3d39',
                  color: "#fff",
                  fontSize: 12,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>1</p>
              </Box>
              <BsWhatsapp size={70} color="white" />
            </Button> */}
            {/* </Tooltip> */}
          </SidebarDrawerProvider>
        </AdditionalUserInfoProvider>
      </SessionProvider>
    </ChakraProvider>
  );
}

export default MyApp;

function MaintanceToast() {
  const router = useRouter();

  const toast = useToast();

  useEffect(() => {
    const now = new Date();
    const maxMaintanceTime = new Date('2024-06-27T09:00:00Z');

    if (now.getTime() > maxMaintanceTime.getTime()) return;

    if (router.pathname.includes('/app')) {
      toast({
        title: 'Manutenção programada no sistema',
        isClosable: true,
        description: (
          <Flex direction={'column'}>
            <Text>
              O sistema poderá passar por instabilidades neste período
            </Text>

            <List pt={4}>
              <ListItem>
                <Flex alignItems={'center'}>
                  <ListIcon as={FiLogIn} />
                  Início: 26/06/204 20:00h GMT-3
                </Flex>
              </ListItem>

              <ListItem>
                <Flex alignItems={'center'}>
                  <ListIcon as={FiLogOut} />
                  Final previsto: 27/06/204 06:00h GMT-3
                </Flex>
              </ListItem>
            </List>
          </Flex>
        ),
        status: 'warning',
        duration: 1000 * 10,
      });
    } else {
    }
  }, [router.pathname, toast]);

  return <></>;
}
